@import "../../variables.scss";
@import "../../mixins.scss";

.working-process-container-card-holder {  
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;
  margin-bottom: 50px;

  @include respond(phone) {
    grid-template-columns: 100%;
    padding: 20px;
  }

  .working-process-card-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .working-process-image-container {
      img {
        width: 100%;
      }
    }
  
    .working-process-data-container {
      margin: 0px 30px;
      padding: 35px 25px 20px 25px;
      border-bottom: 3px solid $light-blue;
      position: relative;
      background-color: #fff;
      bottom: 50px;
    }

  } & .reverse {
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    .working-process-image-container {
      bottom: 50px;
      position: relative;
    }
    .working-process-data-container {
      bottom: 0;
      border-bottom: none;
      border-top: 3px solid #549fd7;
    }
  }  
}