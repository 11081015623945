$dark-blue: #100b4c;
$light-blue: #549fd7;
$hover-light-blue: #2a8ad2;
$white: #fff;
$title-dark-blue: #020f27;
$gray-text: #333333;

$button-color: #252061;
$active-button-color: #549fd7;
$disabled-button-color: #D8CFD0;
$disabled-button-text-color: #BEABAB;

$light-gray: #FAFAFA;