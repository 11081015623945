@import "../../variables.scss";

.offer-holder {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 30px 36px 30px;

  .icon-holder {
    margin-bottom: 15px;
    span {
      padding: 25px;
      background-color: $light-blue;
      border-radius: 50%;
      color: white;
      transition: all .3s;
      &:hover {
        color:#69727d;
      }
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
    
  }
  .description-holder {
    margin: 0;
    // font-size: 15px;
    // line-height: 22.5px;
  }
}