@import '../../variables.scss';

.page-header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;

  .page-header-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
  }

  .page-header-title {
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    color: $white;
    z-index: 9;
  }
}