@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

.about-us-component {
  .about-us-container {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;

    @include respond(phone) {
      grid-template-columns: 100%;
      padding: 20px;
    }

    .about-us-image-container {
      position: relative;
      background-size: cover;
      background-position-x: 50px;
      background-repeat: no-repeat;

      @include respond(phone) {
        width: 98%;
        height: 500px;
      }

      .years-expirience-container {
        position: absolute;
        bottom: 40px;
        left: 0;
        background-color: $light-blue;
        color: $white;
        padding: 30px 40px;
        border: 6px solid $white;
        border-left: none;
        font-size: 20px;
        font-weight: 600;

        p {
          margin: 0;

          &.years-number {
            font-size: 55px;
            font-weight: 900;
            padding: 10px;
          }
        }
      }
    }

    .about-us-description-container {
      padding: 20px 30px;

      @include respond(phone) {
        grid-row-start: 1;
        padding: 0;
      }

      .about-us-description-title {
        font-size: 32px;
        font-weight: 900;
        text-align: left;
        color: $title-dark-blue;
      }

      .about-us-description-subtitle {
        font-size: 20px;
        font-weight: 800;
        color: $light-blue;
        font-style: italic;
        text-align: left;
      }

      .about-us-description-text {
        font-size: 16px;
        text-align: justify;
        margin: 30px 0;
      }

      .about-us-description-list {
        font-size: 16px;
        background-color: $light-gray;
        padding: 30px;
        list-style: none;
        text-align: left;

        li {
          @include respond(phone) {
            margin-bottom: 15px;
          }
          &:before {
            content: "\2012";
            color: $light-blue;
            font-weight: 900;
            display: inline-block;
            width: 1em;
            margin-left: 10px;
            margin-right: 20px;
          }
        }
      }

      .about-us-description-card {
        display: grid;
        grid-template-columns: 19% 51% 28%;
        gap: 1%;
        align-items: center;
        margin-bottom: 30px;

        @include respond(phone) {
          grid-template-columns: 100%;
        }

        .about-us-description-card-image {
          span {
            @include respond(phone) {
              width: 100px !important;
              height: 100px !important;
              margin-bottom: 20px;
            }

            img {
              @include respond(phone) {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }

        .about-us-description-card-text {
          text-align: left;
          margin-left: 30px;

          @include respond(phone) {
            text-align: center;
            margin-left: 0;
            margin-bottom: 20px;
          }

          p {
            margin: 0;

            &.about-us-description-card-text-title {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: 10px;

              @include respond(phone) {
                font-size: 16px;
              }
            }
          }
        }

        img {
          width: 100%;

          @include respond(phone) {
            width: 40%;
          }
        }
      }
    }
  }
}
