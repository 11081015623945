@import "../../variables.scss";

.contact-form-main-container {
  background-color: $light-blue;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  align-items: initial;
  justify-content: space-between;

  &.light {
    background-color: $white;
    box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 69%);

    .faq-contact-form-container {
      .contact-form {
        .ant-form-item {
          input {
            background-color: $light-gray;
            box-shadow: none;
            border: none;
          }

          textarea {
            background-color: $light-gray;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
  }

  .faq-contact-title {
    background-color: $dark-blue;
    color: $white;
    font-size: 20px;
    font-weight: 600;
    padding: 15px 20px;
    margin-bottom: 30px;
  }

  .faq-contact-form-container {
    .contact-form {
      text-align: left;

      .ant-form-item {
        input {
          border-radius: 0;
          padding: 14px;
          box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 69%);
        }

        textarea {
          border-radius: 0;
          padding: 14px;
          box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 69%);
        }

        .ant-form-item-explain-error {
          color: darkred;
          text-align: left;
        }

        .ant-input-status-error {
          border-color: darkred;
        }
      }
    }
  }
}
