@import "../../../variables.scss";
@import "../../../mixins.scss";

.basic-header {
  .top-header {
    background-color: $light-blue;
    color: $white;
    padding: 20px;

    .top-header-col {
      display: grid;
      grid-template-columns: 2fr 6fr 1fr;

      @include respond(phone) {
        grid-template-columns: 2fr 6fr 2fr;
        margin: 0;
        align-items: center;
      }

      .mobile-menu-button {
        display: none;

        @include respond(phone) {
          font-size: 30px;
          display: block;
          margin-right: 20px;
        }
      }

      .logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 200px;
        }
      }

      .info-container {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;

        @include respond(phone) {
          display: none;
        }
      }

      .localization-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .language-icon {
          width: 25px;
          margin: 10px;
          opacity: 0.7;

          &:hover {
            cursor: pointer;
          }

          &.active {
            opacity: 1;
            filter: drop-shadow(0px 0px 5px $white);
          }
        }
      }
    }
  }

  .menu-container {
    background-color: $dark-blue;
    color: $white;

    @include respond(phone) {
      display: none;
      left: -100%;
    }

    &.mobile-view {
      display: block;
      position: fixed;
      background-color: $dark-blue;
      opacity: 0.98;
      z-index: 1000;
      // left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      transition: all .5s;
      touch-action: none;

      .menu-containe-col {
        grid-template-rows: 5fr 1fr;
        grid-template-columns: none;
        margin: 0;
        width: 100%;

        .menu-items-container {
          margin-top: 30px;
          width: 100%;
          flex-direction: column;
        }
      }
    }

    &.open-mobile {
      left: 0;
    }

    .mobile-menu-button-close {
      display: none;
      position: absolute;
      right: 20px;
      top: 25px;
      font-size: 30px;
      z-index: 1001;

      @include respond(phone) {
        display: block;
      }
    }

    .menu-containe-col {
      display: grid;
      grid-template-columns: 5fr 5fr 1fr;

      .menu-items-container {
        display: flex;
        align-items: center;

        .menu-item {
          text-transform: uppercase;
          font-weight: bold;
          padding: 20px;

          &:hover {
            cursor: pointer;
            color: $light-blue;
            text-shadow: 0px 0px 8px $light-blue;
          }
        }
      }

      .menu-social-media-container {
        font-size: 25px;
        display: grid;
        align-items: center;
        gap: 20px;
        grid-template-columns: auto auto auto;
        padding-right: 20px;
        color: $white;

        .anticon {
          &:hover {
            cursor: pointer;
            color: $light-blue;
            filter: drop-shadow(0px 0px 8px $light-blue);
          }
        }
      }
    }
  }
}
