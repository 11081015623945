@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

.carousel-container {
  .carousel-main-container {
    height: 600px;

    .slick-list {
      height: 100% !important;
    }

    .slick-slide {

      &.slick-current {

        .carousel-slide-container{
  
          .carousel-description-container {
            position: absolute;
            z-index: 99;
            bottom: 30%;
            left: 100px;
            text-align: left;
            width: 60%;

            @include respond(phone) {
              left: 20px;
              width: 80%;
            }
    
            &.slide1 {
              animation-name: example;
              animation-duration: 2s;
            }
            &.slide2 {
              animation-name: example;
              animation-duration: 2s;
            }
            &.slide3 {
              animation-name: example;
              animation-duration: 2s;
            }
            &.slide4 {
              animation-name: example;
              animation-duration: 2s;
            }
          }
        }
      }
    }
    .carousel-slide-container {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 600px;

      img {
        width: 100%;
        height: 100%;
      }

      .carousel-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.6;
      }

      .carousel-description-container {
        position: absolute;
        z-index: 99;
        bottom: -500px;
        left: 100px;
        text-align: left;
        width: 60%;

        @include respond(phone) {
          left: 20px;
        }

        .carousel-description-title {
          font-size: 55px;
          font-weight: 800;
          color: $white;
          text-transform: uppercase;

          @include respond(phone) {
            font-size: 22px;
          }
        }

        .carousel-description-subtitle {
          color: $white;
          font-size: 16px;
          font-weight: 20px;
          margin-bottom: 50px;

          @include respond(phone) {
            font-size: 14px;
          }
        }
      }
    }
  }

  @keyframes example {
    from {bottom: -500px;}
    to {bottom: 30%;}
  }
}
