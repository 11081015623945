@import "../../../variables.scss";
@import "../../../mixins.scss";

.footer-container {
  background-color: $dark-blue;
  color: $white;
  padding: 50px 0;

  .footer-component-col {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 2%;
    align-items: center;

    @include respond(phone) {
      grid-template-columns: none;
      grid-row: auto;
    }

    .footer-info {
      @include respond(phone) {
        margin-bottom: 30px;
      }

      .footer-logo-container {
        img {
          width: 100px;

          @include respond(phone) {
            width: 70px;
          }
        }
      }

      .footer-desc-container {
        margin-bottom: 15px;
      }

      .footer-social-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 25px;

        .anticon {
          &:hover {
            color: $light-blue;
            cursor: pointer;
          }
        }
      }
    }

    .footer-contact {
      @include respond(phone) {
        margin-bottom: 30px;
      }

      .footer-contact-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .footer-contact-data {
        margin-bottom: 10px;
      }
    }

    .footer-links {
      @include respond(phone) {
        margin-bottom: 30px;
      }

      .footer-link-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .footer-link-data {
        margin-bottom: 10px;

        span {
          color: $white;
          text-decoration: none;

          &:hover {
            cursor: pointer;
            color: $light-blue;
          }
        }
      }
    }
  }
}
