@import '../../variables.scss';

.services-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.inverted {
    flex-direction: column-reverse;

    .services-card-image-container {
      margin-bottom: 0;
    }

    .services-card-data-container {
      margin-bottom: -70px;
    }

    .services-card-data-container {
      display: flex;
      flex-direction: column-reverse;

      .services-card-info-container {
        padding: 12px 35px 85px 35px;
      }
    }
  }

  .services-card-image-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -70px;
    z-index: 9;


    img {
      width: 100%;
    }
  }

  .services-card-data-container {

    .services-card-info-container {
      box-shadow: 0px -10px 13px -1px rgba(0, 0, 0, 0.1);
      padding: 85px 35px 12px 35px;

      h1 {
        font-size: 18px;
      }

      p {
        padding: 0 20px;
        color: $gray-text
      }
    }

    .services-card-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.15);

      a {
        padding: 18px 30px 18px 30px;
        color: $light-blue;
        font-weight: 700;
        font-size: 16px;

        &:hover {
          color: $dark-blue;
        }
      }
    }
  }
}
