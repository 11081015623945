@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

.gallery-component {
  padding: 50px 0 50px 0;

  @include respond(phone) {
    padding: 20px;
  }

  .gallery-component-title {
    font-size: 35px;
    font-weight: 800;
    color: $title-dark-blue;
  }

  .gallery-component-subtitle{
    width: 60%;
    margin: auto;

    @include respond(phone) {
      width: 90%;
    }
  }

  .gallery-container-holder {
    margin-top: 30px;
    // display: grid;
    // grid-template-columns: 32% 32% 32%;
    // gap: 2%;
    margin-bottom: 50px;

    @include respond(phone) {
      grid-template-columns: 100%;
    }
  }

  .view-more-gallery-button {
    @include respond(phone) {
      margin-top: 30px;
    }
  }
}
