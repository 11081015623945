@import "../../../../../../variables.scss";
@import "react-alice-carousel/lib/scss/alice-carousel.scss";
@import "../../../../../../mixins.scss";

.partners-main-container {
  position: relative;
  width: 100%;
  height: 150px;

  @include respond(phone) {
    height:50px;
  }

  .partners-container {
    position: absolute;
    width: 100%;
    top: -100px;
    background-color: $white;
    padding: 30px;
    box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;

    @include respond(phone) {
      top: -50px;
    }

    img {
      @include respond(phone) {
        height: 50px;
      }
    }
   
  }
}