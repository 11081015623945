@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.gallery-page {

  .gallery-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    padding: 20px 0;

    @include respond(phone) {
      padding: 20px;
    }
  }
}