@import "../../variables.scss";

.heading-container {
  width: fit-content;
  border: 2px solid $light-blue;
  color: $light-blue;
  text-transform: uppercase;
  padding: 5px 15px;
  font-size: 18px;
  font-weight: 600;

  &.dark {
    border: 2px solid $dark-blue;
    color: $dark-blue;
  }
}
