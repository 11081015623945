@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.contact-page {
  .contact-form-container {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin: 60px 0;

    @include respond(phone) {
      grid-template-columns: 100%;
    }

    .contact-form-image-container {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      .contact-form-image-text-container {
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: $light-blue;
        color: $white;
        border-left: none;
        width: 90%;

        @include respond(phone) {
          display: none;
        }

        .image-text-title {
          text-align: left;
          font-size: 30px;
          padding: 0px 50px;
          font-weight: 700;
        }
        .image-text-subtitle {
          font-size: 16px;
          padding: 0 50px;
          text-align: left;
          margin-bottom: 50px;
        }
      }
    }

    .contact-page-form-message-container {
      height: fit-content;

      @include respond(phone) {
        grid-row-start: 1;
        padding: 20px;
      }

      .contact-page-form-title {
        font-size: 35px;
        font-weight: 900;
        margin: 30px 0;
        text-align: left;
      }

      .contact-page-form-subtitle {
        font-size: 15px;
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }

  .contact-info-container {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 4%;
    margin: 100px 0;

    @include respond(phone) {
      grid-template-columns: 100%;
      padding: 20px;
    }
  }
}
