@import '../../variables.scss';

.Button {
  font-size: 15px;
  color: $white;
  background: $button-color;
  text-transform: uppercase;
  border-radius: 0px;
  height: auto;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  text-align: center;
  border: 2px solid transparent;

  &:hover{
    background: $white!important;
    color: $button-color!important;
    border-color: $button-color !important;
  }

  &:active {
    background: $active-button-color;
    color: $white;
    border-color: transparent;
  }

  &:focus {
    background: $active-button-color;
    color: $white;
    border-color: transparent;
  }
  
   &.Disabled {
    background: $disabled-button-color;
    color: $disabled-button-text-color;
    &:hover{
      background: $disabled-button-color;
      color: $disabled-button-text-color;
    }
   }

  p {
    padding: 0;
    margin: 0;
  }

  &.Secondary {
    background: $white;
    color: $button-color;
    border-color: $button-color;

    &:hover{
      background: $button-color;
      color: $white;
      border-color: $white;
    }
    &:active {
      background: $active-button-color;
      color: $button-color;
      border-color: transparent;
    }
    &:focus {
      background: $active-button-color;
      color: $button-color;
      border-color: transparent;
    }
  }
}
