@import "../../../../variables.scss";
@import "../../../../mixins.scss";
.about-us-page {
  .about-us-page-container {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;

    @include respond(phone) {
      grid-template-columns: 100%;
      padding: 20px;
    }

    .introduction-container {
      padding-top: 50px;
      text-align: left;

      .introduction-title {
        font-size: 33px;
        font-weight: 800;
        text-align: left;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      .introduction-description {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }
    }

    .quote-container {
      padding-top: 50px;
      text-align: left;

      .quote-title {
        font-size: 20px;
        font-style: italic;
        text-align: left;
        margin-bottom: 25px;
      }
      .quote-subtitle {
        font-size: 20px;
        font-weight: 800;
        color: #549fd7;
        text-align: left;
      }
      .quote-description-list {
        font-size: 16px;
        background-color: #fafafa;
        padding: 30px;
        list-style: none;
        text-align: left;

        li:before {
          content: "‒";
          color: #549fd7;
          font-weight: 900;
          display: inline-block;
          width: 1em;
          margin-left: 10px;
          margin-right: 20px;
        }
      }
    }
  }
}
.offers-holder {
  .offers-container {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 5% 5%;
    padding: 33px 0px 65px 0px;

    @include respond(phone) {
      grid-template-columns: 100%;
      padding: 20px;
      margin-bottom: 70px;
    }
  }
}
.aboutus-gallery {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 25px;
  padding-bottom: 75px;

  @include respond(phone) {
    padding: 20px;
  }

  .leftImgHolder {
    img {
      width: 100%;
      padding-right: 10px;
      padding-bottom: 10px;
      background-color: #fff;
    }
  }
  .rightImgHolder {
    margin-left: -50px;
    z-index: -1;
    margin-top: 50px;
    img {
      width: 100%;
    }
  }
}
.working-process-holder {

  .heading-container  {
    margin: auto;
  }

  .working-process-description {
    margin-bottom: 30px;
  
    @include respond(phone) {
      padding: 0 20px;
    }
  }
}

