@import "../../../../variables.scss";
@import "../../../../mixins.scss";

.album-page {

  .album-container {
    padding: 20px 0;

    @include respond(phone) {
      padding: 20px;
    }
  }
}