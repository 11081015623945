@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

.services-component {
  background-color: $light-gray;
  margin-top: 100px;
  padding: 50px 0 100px 0;

  @include respond(phone) {
    padding: 20px;
  }

  .services-component-title {
    font-size: 35px;
    font-weight: 800;
    color: $title-dark-blue;
  }

  .services-component-subtitle{
    width: 60%;
    margin: auto;

    @include respond(phone) {
      width: 90%;
    }
  }

  .services-container-card-holder {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    margin-bottom: 50px;

    @include respond(phone) {
      grid-template-columns: 100%;
    }
  }

  .view-more-services-button {
    @include respond(phone) {
      margin-top: 30px;
    }
  }
}
