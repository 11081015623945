@import './variables.scss';

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 720px) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media only screen and (max-width: 1024px) and (min-width: 721px) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == smallPhone {
    @media only screen and (max-width: 560px) and (min-width: 320px) {
      @content;
    }
  } @else if $breakpoint == extraSmallPhone {
    @media only screen and (max-width: 320px) and (min-width: 260px) {
      @content;
    }
  }
}

