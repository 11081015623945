@import "../../../../../../variables.scss";
@import "../../../../../../mixins.scss";

.faq-component {
  background-color: $light-gray;

  .faq-main-container {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;

    @include respond(phone) {
      grid-template-columns: 100%;
    }

    .faq-container {
      padding-top: 50px;

      @include respond(phone) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .faq-title {
        font-size: 33px;
        font-weight: 800;
        text-align: left;
        margin-bottom: 25px;
      }

      .faq-description {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 40px;
      }

      .faq-collapse-container {
        .ant-collapse {
          background: transparent;
          border: none;
          border-radius: 0;

          .ant-collapse-item {
            margin-bottom: 24px;
            border: none;
            padding: 8px;
            box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.2);

            &.ant-collapse-item-active {
              .ant-collapse-header {
                color: $light-blue;

                .ant-collapse-expand-icon {
                  span {
                    stroke: $light-blue;
                  }
                }
              }
            }

            .ant-collapse-header {
              flex-direction: row-reverse;
              text-align: left;
              font-size: 16px;
              font-weight: 600;

              .ant-collapse-expand-icon {
                span {
                  stroke-width: 90;
                  stroke: black;
                  font-size: 12px;
                }
              }
            }

            .ant-collapse-content {
              border: none;
              text-align: left;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }

    .faq-contact-main-container {
      padding: 50px;

      @include respond(phone) {
        padding: 20px;
        padding-bottom: 60px;
      }

      .faq-contact-container {
        background-color: $light-blue;
        padding: 30px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 60px);
        align-items: initial;
        justify-content: space-between;

        .faq-contact-title {
          background-color: $dark-blue;
          color: $white;
          font-size: 20px;
          font-weight: 600;
          padding: 15px 20px;
          margin-bottom: 30px;
        }

        .faq-contact-form-container {
          .contact-form {
            text-align: left;

            .ant-form-item {
              input {
                border-radius: 0;
                padding: 14px;
                box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 69%);
              }

              textarea {
                border-radius: 0;
                padding: 14px;
                box-shadow: 0px 0px 13px -4px rgb(0 0 0 / 69%);
              }

              .ant-form-item-explain-error {
                color: darkred;
                text-align: left;
              }

              .ant-input-status-error {
                border-color: darkred;
              }
            }
          }
        }
      }
    }
  }
}
