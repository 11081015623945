@import '../../variables.scss';

.header-info {
  display: flex;
  align-items: center;
  justify-content: center;

  .header-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid $dark-blue;
    font-size: 30px;
    margin-right: 15px;
  }

  .header-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header-info-container-title {
      font-weight: 800;
    }
  }
}