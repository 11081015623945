@import "../../variables.scss";
@import "../../mixins.scss";

.contact-info {
  position: relative;
  text-align: left;
  box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.15);
  padding: 65px 30px 25px 30px;

  @include respond(phone) {
   text-align: center;
  }

  .icon-container {
    position: absolute;
    color: $white;
    background-color: $light-blue;
    padding: 10px 15px;
    left: 30px;
    top: -20px;
    font-size: 30px;

    @include respond(phone) {
      left: 42%;
     }

    &.dark {
      background-color: $dark-blue;
    }
  }

  .title-container {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .contact-info-data {
    font-size: 15px;
    margin-bottom: 5px;
  }
}
